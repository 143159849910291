import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Button from "@ui/button";
import Image from "@ui/image";
import { StyledSection } from "./style";

const IndustriesInnerIntro = ({ data, layout, ...props }) => {
   
    return (
        <StyledSection layout={layout} {...props}  id={data.section}>
            <Container>
                <Row>
                    <Col lg={6}>
                    <SectionTitle
                        subtitle={data.section_title?.subtitle}
                        title={data.section_title?.title}
                        description={data.section_title?.description}
                    />
                    {data.buttons &&
                    data?.buttons?.map(({ id, content, ...rest }) => (
                    <Button key={id} m="7px" {...rest}>
                        {content}
                    </Button>
                    ))}
                    </Col>
                    <Col lg={6}>
                        <Image
                        src={data.images[0]?.src}
                        alt={data.images[0]?.alt || "Andovar Studios"}
                        title={data.images[0]?.alt || "Andovar Studios"}
                        />
                    </Col>
                </Row>
            </Container>
        </StyledSection>
    );
};

IndustriesInnerIntro.propTypes = {
    layout: PropTypes.oneOf([1, 2, 3]),
};

IndustriesInnerIntro.defaultProps = {
    layout: 1,
};

export default IndustriesInnerIntro;
